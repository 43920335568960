.lancehng {
  position: fixed;
  right: 50px;
  top: 50px;
  z-index: 9999;
  width: 125px;
  height: 125px;
  cursor: move;
  &:hover {
    .lancehng-qr-code {
      opacity: 1;
    }
  }
}
.sprite-lancehng {
  width: 250px;
  height: 250px;
  background-image: url('../assets/ani/sprite.webp');
  background-repeat: no-repeat;
  background-position: 0 0;
  animation: play 1s steps(61) infinite;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.5);
  transform-origin: right top;
  //   cursor: pointer;
}

@keyframes play {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -15250px;
  }
}

.lancehng-qr-code {
  opacity: 0;
  position: absolute;
  transition: all 0.5s;
  width: 260px;
  transform: scale(0.5);
  transform-origin: right top;
  background: image-set(
    url('../assets/images/about/box-bg-1x.webp') 1x,
    url('../assets/images/about/box-bg-2x.webp') 2x
  );
  background-size: 100% 100%;
  right: 125px;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  padding: 20px 0;
  &-title {
    font-family: PingFangSC;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    line-height: 22px;
    padding: 40px 0 20px;
  }
  &-img {
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-text {
    font-family: PingFangSC;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
    margin-top: 10px;
  }
}
